<template language="html">
  <div class="gestao-menu">
    <crud
      :title="'Gestão de menu'"
      buttonAddItemText="Novo menu"
      :dialog.sync="dialog"
      :formTitle="formTitle"
      :callBackModalCancelar="fechar"
      :callBackModalSalvar="salvar"
      :callBackModalInsert="resetFields"
      :loadingButtonSave="loadingControl.loadingSalvar"
      :tamanhoModalCrud="600"
      searchLabelText="Pesquisar na lista"
      :searchTextField.sync="searchTextField"
      :dialogDelete.sync="dialogDelete"
    >
      <template slot="data-table">
        <v-data-table
          :loading="loadingControl.dataTable"
          :headers="computedHeaders"
          :items="listaMenus"
          :search="searchTextField"
          items-per-page-text="Linhas por página"
          no-results-text="Nenhum registro encontrado"
          no-data-text="Nenhum registro encontrado"
          mobile-breakpoint="0"
        >
          <template v-slot:[`item.rota`]="{ item }">
            <span v-if="item.rota !== ''">{{ item.rota }}</span>
            <span v-else>
              <v-chip label> Lista </v-chip>
            </span>
          </template>
          <template v-slot:[`item.acao`]="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="toggleModalLateral(item)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2"> mdi-eye </v-icon>
                    <span>Visualizar</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item @click="editar(item)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2"> mdi-pencil </v-icon>
                    <span>Editar</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item @click="remover(item, true)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2"> mdi-delete </v-icon>
                    <span>Apagar</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </template>
      <!-- ------------- MODAL DE DELETE --------------------------------------------- -->
      <template slot="modalDelete/titleModal">Excluir o registro?</template>
      <template slot="modalDelete/contentModal">
        <p>
          Atenção! Você está prestes a realizar uma ação que não pode ser
          desfeita.
        </p>
        <p>Você realmente deseja excluir esse registro?</p>
      </template>
      <template slot="modalDelete/actionsModal">
        <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
        <v-btn color="error" @click="remover()">Excluir</v-btn>
        <v-spacer class="d-none d-md-block"></v-spacer>
      </template>
      <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
      <template slot="contentModalCrud">
        <v-container fluid>
          <v-row wrap>
            <v-col cols="12" md="12">
              <v-text-field
                outlined
                label="Nome"
                data-vv-as="nome"
                dense
                persistent-hint
                v-model="objetoEdicao.nome"
                :hint="errors.first('nome')"
                :error="errors.collect('nome').length ? true : false"
                v-validate="'required'"
                data-vv-scope="menu"
                data-vv-validate-on="change"
                id="nome"
                name="nome"
                trim
              />
            </v-col>
            <v-col cols="12" md="12">
              <v-autocomplete
                :items="listaPermissoes"
                label="Permissão"
                data-vv-as="menu"
                data-vv-scope="cadastro"
                dense
                item-text="name"
                item-value="name"
                outlined
                persistent-hint
                v-model="objetoEdicao.permissao"
                id="permissao"
                name="permissao"
                trim
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                label="Icone"
                data-vv-as="icone"
                dense
                persistent-hint
                v-model="objetoEdicao.icon"
                :hint="errors.first('icone')"
                :error="errors.collect('icone').length ? true : false"
                v-validate="'required'"
                data-vv-scope="menu"
                data-vv-validate-on="change"
                id="icone"
                name="icone"
                trim
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                label="Rota"
                data-vv-as="rota"
                dense
                persistent-hint
                v-model="objetoEdicao.rota"
                trim
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                label="Ordem"
                data-vv-as="ordem"
                dense
                persistent-hint
                type="number"
                v-model="objetoEdicao.ordem"
                :hint="errors.first('ordem')"
                :error="errors.collect('ordem').length ? true : false"
                v-validate="'required'"
                data-vv-scope="menu"
                data-vv-validate-on="change"
                id="ordem"
                name="ordem"
                trim
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                :items="listaItensMenus"
                label="Menu"
                data-vv-as="menu"
                data-vv-scope="menu"
                dense
                item-text="nome"
                item-value="id"
                outlined
                persistent-hint
                v-model="objetoEdicao.id_menu"
                id="menu"
                name="menu"
                trim
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </crud>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="#001325" size="75">
        <img :src="image" height="45" alt="" />
      </v-progress-circular>
    </v-overlay>
    <modalLateral
      :show="modalLateral.show"
      :hide="toggleModalLateral"
      :title="modalLateral.title"
      :headers="
        headersModal.filter((header) => {
          return headersModal.value != 'visualizar';
        })
      "
      :item="modalLateral.item"
    >
      <template v-slot:[`item.id_menu`]="{ item }">
        {{
          listaItensMenus.indexOf(item.id) == null
            ? "Gestão"
            : listaItensMenus.indexOf(item.id).nome
        }}
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span
          >{{ item.created_at | dateFormatBr() }}
          {{ item.created_at | timeFormatBr() }}</span
        >
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        <span
          >{{ item.updated_at | dateFormatBr() }}
          {{ item.updated_at | timeFormatBr() }}</span
        >
      </template>
    </modalLateral>
  </div>
</template>
<script>
import logo from "@/assets/images/logos/legiio.png";
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";
export default {
  name: "gestao-menu",
  inject: ["$validator"],
  components: { Crud, ModalLateral },
  metaInfo() {
    return {
      title: "Menu",
    };
  },
  data() {
    return {
      image: logo,
      searchTextField: "",
      dialog: false,
      loadingControl: {
        dataTable: false,
        loadingSalvar: false,
      },
      headers: [
        { text: "Nome", value: "nome", sortable: true },
        {
          text: "Permissão",
          value: "permissao",
          sortable: false,
        },
        { text: "Ordem", value: "ordem", sortable: false },
        { text: "Rota", value: "rota", sortable: false },
        { text: "Ações", value: "acao", sortable: false, align: "center" },
      ],
      headersModal: [
        {
          text: "Uuid",
          value: "uuid",
        },
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "Permissão",
          value: "permissao",
        },
        {
          text: "Menu",
          value: "id_menu",
        },
        {
          text: "Ordem",
          value: "ordem",
        },
        {
          text: "Icone",
          value: "icon",
        },
        {
          text: "Rota",
          value: "rota",
        },
        {
          text: "Data de criação",
          value: "created_at",
        },
        {
          text: "Última alteração",
          value: "updated_at",
        },
      ],
      listaMenuDataTable: [],
      objetoSalvar: [],
      listaMenu: [],
      item: [],
      dialogDelete: false,
      dialog: false,
      nome: "",
      ordem: "",
      icon: "",
      rota: "",
      id_menu: "",
      modalLateral: {
        show: false,
        item: [],
        title: "",
      },
      buttonAction: false,
      objetoEdicao: {
        nome: "",
        ordem: "",
        icon: "",
        id_menu: "",
        rota: "",
      },
      indexEdicao: 0,
      edicao: false,
    };
  },
  methods: {
    editar(item) {
      this.edicao = true;
      this.indexEdicao = this.listaMenus.indexOf(item);
      this.objetoEdicao = Object.assign({}, item);
      this.dialog = true;
    },
    resetFields() {},
    fechar() {
      this.dialog = false;
      this.objetoEdicao = {};
    },
    salvar() {
      this.$validator.validate("menu.*").then((result) => {
        if (result) {
          this.loadingControl.dataTable = true;

          let url =
            this.edicao === false ? "/menu" : "/menu/" + this.objetoEdicao.uuid;
          let method = this.edicao === false ? "POST" : "PUT";
          let text =
            this.edicao === false ? "Menu cadastrado." : "Menu atualizado.";
          let erro =
            this.edicao === false
              ? "Erro ao salvar menu."
              : "Erro ao atualizar menu.";
          let menuItem = this.objetoEdicao;
          menuItem._method = method;
          menuItem.url = url;

          this.$store
            .dispatch("configuracoes/salvarMenu", { menuItem })
            .then((response) => {
              this.edicao === false
                ? this.listaMenus.push(response)
                : Object.assign(this.listaMenus[this.indexEdicao], menuItem);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: text,
              });
            })
            .catch((error) => {
              console.log(error);
              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "error",
                timeout: 3000,
                text: erro,
              });
            })
            .finally(() => {
              this.loadingControl.dataTable = false;
              this.edicao = false;
            });
        } else {
          this.$store.dispatch("snackBarModule/openSnackBar", {
            color: "error",
            timeout: 3000,
            text: "Preencha todo formulário antes de salvar!",
          });
        }

        this.fechar();
      });
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = item;
        this.dialogDelete = true;
      } else {
        this.loadingControl.dataTable = true;

        const indexItem = this.listaMenus.findIndex(
          (el) => el.uuid == this.item.uuid
        );

        this.$store
          .dispatch("configuracoes/removerMenu", this.item.uuid)
          .then((response) => {
            if (response.data) {
              this.listaMenus.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Registro removido.",
              });
              this.item = {};
            }
          })
          .catch(() => {
            store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possivel remover o registro.",
            });
          })
          .finally(() => {
            this.loadingControl.dataTable = false;
          });

        this.dialogDelete = false;
      }
    },
    toggleModalLateral(item) {
      this.modalLateral.title = this.modalLateral.show ? "" : item.nome;
      this.modalLateral.item = this.modalLateral.show ? [] : item;
      this.modalLateral.show = this.modalLateral.show ? false : true;
    },
  },
  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
    listaMenus: {
      get() {
        return this.$store.getters["configuracoes/getListaMenus"];
      },
      set(value) {
        this.$store.dispatch("configuracoes/setListaMenus", value);
      },
    },
    listaItensMenus: {
      get() {
        return this.$store.getters["configuracoes/getItensMenus"];
      },
      set(value) {
        return value;
      },
    },
    listaPermissoes: {
      get() {
        return this.$store.getters["configuracoes/getPermissoes"];
      },
      set(value) {
        this.$store.dispatch("configuracoes/setPermissoes", value);
      },
    },
    loading: {
      get() {
        return this.$store.getters["configuracoes/getLoading"];
      },
    },
    formTitle() {
      return this.edicao === false ? "Cadastrar menu" : "Editar menu";
    },
  },
  created() {
    //Busca os menus na API
    this.$store.dispatch("configuracoes/carregarMenus");
    this.$store.dispatch("configuracoes/buscarPermissoes");
  },
};
</script>
<style lang="scss"></style>
